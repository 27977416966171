import React from 'react';
import GeneralModal from '../../../../components/UI/modal/GeneralModal';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import ConfirmationContent from './ConfirmationContent';

const SuccessModal = () => {

  const { closeAddReqConclusionModal, conclusionModalState } = usePermitRequestContext();
  const isModalOpen = conclusionModalState.isModalOpen;

  if (!isModalOpen) return;

  return (
    <GeneralModal
      closingFunc={closeAddReqConclusionModal}
      modalSize={'M'}
      title={'התהליך הסתיים בהצלחה'}
      body={<ConfirmationContent />}
      footer={(
        <button className='btn btn-success' onClick={closeAddReqConclusionModal}>אישור</button>
      )}
    />
  );
}

export default SuccessModal;