import { axiosCitizenSticker } from "./api";
import { useProjectConfigContext } from "../contexts/ProjectConfigContext";


export const VALID_PERMIT_ALREADY_EXIST_ERROR = {
    code: 1,
    desplayMsg: 'כבר קיים תו במערכת'
}

export const VALID_REQUEST_ALREADY_EXIST = {
    code: 2,
    desplayMsg: 'קיימת כבר בקשה עם הנתונים'
}

export const PAYMENT_FAILED = {
    code: 3,
    desplayMsg: (
        <>
            בעיה בתהליך סליקת כרטיס האשראי. אנא בדוק תקינות כרטיס האשראי.
            <br />
            במידת הצורך, אנא פנה לחברת האשראי לצורך בדיקה
        </>)
}

export const FILES_NOT_VALID = {
    code: 4,
    desplayMsg: 'התרחשה בעיה בתהליך עיבוד הקבצים - הבקשה לא התקבלה'
}

export const GENERAL = {
    code: 5,
    desplayMsg: 'בעיה כללית בשרת - הבקשה לא התקבלה'
}

export const BAD_REQUEST = {
    code: 6,
    desplayMsg: 'נוצרה בעיה בגלל הנתונים השתקבלו'
}


export default function useAddPermitRequestApi() {

    const { authState } = useProjectConfigContext();

    async function addPermitRequestApi(formData) {

        const config = {
            headers: { Authorization: `Bearer ${authState.token}` }
        };

        const reqToSend = JSON.parse(JSON.stringify(formData));
        console.log(`reqToSend: ${reqToSend}`)
        
        const response = await axiosCitizenSticker.post('/addCitizenSticker', reqToSend, config);
        return await response.data;
    }

    return addPermitRequestApi;
}