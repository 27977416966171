import React, { useEffect, useState } from 'react';
import StudentPermitType from './StudentPermitType';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import GoldenAgePermit from './GoldenAgePermit';
import { useProjectConfigContext } from '../../../../contexts/ProjectConfigContext';

function PermitRequestType() {

    const { values, setFieldValue } = usePermitRequestContext();
    const { projectConfigState } = useProjectConfigContext();
    const [radioBtnState, setChecked] = useState([true, false]);
    const hasGoldenPermit = projectConfigState.tdsConfig.gilHaZahavOption;
    const hassTudentPermit = projectConfigState.tdsConfig.gilHaZahavOption;

    useEffect(() => {

        setChecked([
            (values.permitType === 0 || values.permitType === 1 || values.permitType === 2) ? true : false,
            values.permitType === 1 ? true : false,
            (values.permitType === 0 || values.permitType === 1) ? true : false,
            values.permitType === 2 ? true : false
        ]);
    }, [values.permitType]);

    return (
        <>
            {hasGoldenPermit ? <GoldenAgePermit radioBtnState={radioBtnState} handleClick={handleClick} /> : ''}
            {hassTudentPermit ? <StudentPermitType radioBtnState={radioBtnState} handleClick={handleClick} /> : ''}
        </>
    );

    function handleClick(e) {
        setFieldValue('permitType', Number(e.currentTarget.value));
    }
}

export default PermitRequestType;