import { useState } from "react";
import InputGroup from "../../../../../components/form/InputGroup";
import { usePermitRequestContext } from "../../../../../contexts/PermitRequestContext";
import { useProjectConfigContext } from "../../../../../contexts/ProjectConfigContext";
import RentingApartmentFiles from "./RentingApartmentFiles";
import HasBusinessFiles from "./HasBusinessFiles";
import SectionTitle from "../../SectionTitle";

function ExtraFileFormSection() {
    const { projectConfigState } = useProjectConfigContext();
    const { handleChange, errors, touched, handleBlur } = usePermitRequestContext();

    const projectId = projectConfigState.projectId;

    return (
        <div className="row mt-2 mb-2">
            <SectionTitle title='קבצים נוספים' />
            {projectConfigState.extraFile ? (
                <div className="row">
                    <div className='col-sm-4'>
                        <InputGroup
                            type={'file'}
                            name={'extra1'}
                            label={'קובץ נוסף?'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorMsg={(errors['extra1'] && touched['extra1']) ? errors['extra1'] : undefined}
                        />
                    </div>
                </div>
            ) : ''}

            {projectId == 51 || projectId == 1 ? <RentingApartmentFiles /> : ''}
            {projectId == 51 || projectId == 1 ? <HasBusinessFiles /> : ''}
        </div>
    );
}

export default ExtraFileFormSection;