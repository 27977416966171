import React from 'react';
import Style from '../permitRequest.module.css';
import SelectStreet from '../../../../components/form/select/SelectStreet';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import InputGroup from '../../../../components/form/InputGroup';
import SectionTitle from '../SectionTitle';
import FormSection from './FormSection';

function ContactDetailsFormSection() {

    const { handleChange, errors, values, touched, handleBlur, setFieldValue, setFieldTouched } = usePermitRequestContext();

    const contaDetails = values.contactDetails;
    const contaDetailsErrors = errors.contactDetails ? errors.contactDetails : {};
    const contaDetailsTouched = touched.contactDetails ? touched.contactDetails : {};

    return (
        <FormSection>
            <SectionTitle title='פרטי הכתובת' />
            <div className='row align-items-end'>
                <div className='col-md-6 col-lg-4'>
                    <SelectStreet
                        name={'streetId'}
                        value={contaDetails.streetId}
                        isRequired={true}
                        handleChange={option => setFieldValue('contactDetails.streetId', option.value)}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('contactDetails.streetId', true);
                        }}
                        errorMsg={(contaDetailsErrors.streetId && contaDetailsTouched.streetId) ? contaDetailsErrors.streetId : undefined} />
                </div>

                <div className='col-md-3 col-lg-2'>
                    <InputGroup
                        name={'contactDetails.house'}
                        label={'בית'}
                        value={contaDetails.house}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(contaDetailsErrors
                            .house && contaDetailsTouched.house) ? contaDetailsErrors.house : undefined}
                    />
                </div>
                <div className='col-md-3 col-lg-2'>
                    <InputGroup
                        name={'contactDetails.appartment'}
                        label={'דירה'}
                        value={contaDetails.appartment}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(contaDetailsErrors
                            .appartment && contaDetailsTouched.appartment) ? contaDetailsErrors
                            .appartment : undefined}
                    />

                </div>

            </div>

            <div className='row align-items-end'>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        name={'contactDetails.email'}
                        label={'דואר אלקטרוני'}
                        value={contaDetails.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(contaDetailsErrors
                            .email && contaDetailsTouched.email) ? contaDetailsErrors
                            .email : undefined}
                    />

                </div>
            </div>

            <div className='row align-items-end'>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        name={'contactDetails.mobile'}
                        label={'טלפון נייד'}
                        value={contaDetails.mobile}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(contaDetailsErrors
                            .mobile && contaDetailsTouched.mobile) ? contaDetailsErrors
                            .mobile : undefined} />

                </div>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        name={'contactDetails.phone'}
                        label={'טלפון נוסף'}
                        value={contaDetails.phone}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(contaDetailsErrors
                            .phone && contaDetailsTouched.phone) ? contaDetailsErrors
                            .phone : undefined}
                    />

                </div>
            </div>
        </FormSection>
    );
}

ContactDetailsFormSection.defaultProps = {
    isChecked: false
}

export default ContactDetailsFormSection;