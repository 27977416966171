import PermitType from "../types/PermitType";

export function calcPaymentAmount(permitType, tdsConfig) {

    let paymentAmount = tdsConfig.payment;
    const permitTypeInt = Number(permitType);

    if(permitTypeInt === PermitType.REGULAR) {
        paymentAmount = tdsConfig.payment;
    } else if (permitTypeInt === PermitType.GOLDEN) {
        paymentAmount = tdsConfig.gilHaZahavPayment;
    } else if (permitTypeInt === PermitType.STUDENT) {
        paymentAmount = tdsConfig.studentPayment;
    } else if (permitTypeInt === PermitType.MUNICIPAL_EMPLOYEE) {
        paymentAmount = tdsConfig.municipalEmployeePayment;
    }

    return paymentAmount;
}