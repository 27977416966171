import { useProjectConfigContext } from "../../../contexts/ProjectConfigContext";
import TakanonCheckBox from "./form/TakanonCheckBox";

export default function Takanon() {

    const { projectConfigState } = useProjectConfigContext();

    let takanonMessage = '';
    const projectId = projectConfigState.projectId;

    if (projectId == 69) {

        takanonMessage = (
            <>
                <h4>תו החנייה הדיגיטלי הוא לשנה יש להגיש בקשה חדשה בחלוף שנה ממועד קבלת התו</h4>
                <p>הצהרת המבקש/ת</p>
                <p>• המבקש נדרש להעביר למועצה הודעה בכתב עם מכירת הרכב או החזרת הרכב / ליסינג רכב עבודה .</p>
                <p>• המבקש נדרש להעביר הודעה למועצה על שינוי מגורים, כתובת מקום המגורים.</p>
                <p>• הנני מצהיר, כי כל המידע המפורט בבקשה ובמסמכים המצורפים לה נכון. ידוע לי כי מסירת פרטים כוזבים לעניין הגשת הבקשה ושימוש בתו שלא בהתאם להתחייבותי כמפורט למעלה הינם עבירה על החוק וזכות השימוש בתו תפקע ולמועצה תהיה הזכות לבטל את התו ליתר הסועדים העומדים לרשותה, לידיעתך המידע שנמסר על ידך בטופס זה נשמר במאגרי המידע של המועצה על מנת לתת לך שירות מטעמה.</p>
                <p>קראתי את ההצהרה ואני מסכים/ה לתנאים.</p>
            </>
        );

    } else if (projectId == 26) {

        takanonMessage = (
            <>
                <strong>עם רכישת תו החנייה הרני מצהיר ומתחייב:</strong>

                <p>1. להדביק את המדבקה על השמשה הקדמית בצד ימין של רכבי (שתהיה גלויה לעין) ולשמור על שלמותה.</p>
                <p>2. להסיר את המדבקה מהרכב עם שינוי
                    מקום מגוריי (מחוץ לעיר עפולה ו/או לרחוב בעיר עפולה שאינו עולה
                    בקנה אחד עם הזכויות שתו החנייה מקנה) ו/או עם מכירתו ו/או העברתו לאחר
                    ו/או עם החזרת הרכב למקום עבודתי ו/או עם פקיעת תוקף התו המוטבע עליו.
                </p>
                <p>3. לא להעביר את המדבקה לאחר.</p>
                <p>
                    <strong>
                        הנני מצהיר, כי כל המידע המפורט בבקשתי לקבלת המדבקה ובמסמכים המצורפים אליה נכון, מלא ומדוייק.
                    </strong>
                    <br />
                    ידוע לי, כי מסרתי
                    פרטים כוזבים לעיניין הגשת הבקשה והשימוש במדבקה שלא בהתאם להתחייבותי
                    כמפורט למעלה הינם עבירה על החוק - דבק אשר עשוי להוביל להגשת תלונה
                    למשטרת ישראל, ועיריית עפולה תהיה הזכות לבטל את המדברקה
                    ו/או את הזכיותיי לפיה ו/או את זכותי לקבלת תו חנייה חדש.
                </p>
            </>
        );

    } else if (projectId == 61) {
        <>
            alert = <img src="permitRequest/takanonImg.png" alt="תקנון" />

        </>
    } else if (projectId == 94) {

        takanonMessage = (
            <>
                <strong>הצהרת המבקש</strong>
                <p>אני מתחייב בזאת כי במידה ואמכור את רכבי הנ"ל ו/או אעתיק את מגוריי אדווח על כך תוך 7 ימים לרשות החניה והפיקוח של עיריית קריית ביאליק.</p>
                <p>1. אם בבעלותך רכב נוסף-יש להגיש טופס נוסף.</p>
                <p>2. בעת רכישת רכב חדש-יש להגיש טופס חדש.</p>
                <p>3. סטודנט יש להגיש בקשה כל שנה.</p>

            </>
        );

    } else {

        takanonMessage = (
            <>
                <p>הריני מצהיר בזאת כי כל הפרטים לעיל נכונים ומלאים.<br />
                    ידוע לי כי דיווח חלקי או כוזב הינה עבירה על החוק, וכן ידוע לי כי לרשות יש זכות לבדוק את נכונות הפרטים הנ"ל בכל האמצעים העומדים לרשותה כחוק.</p>
                <p> הנני מאשר להשתמש בפרטים שהוזנו על ידי במסגרת הבקשה לצרכי העירייה על פי כל דין.
                    אני מעוניין/ת לקבל תזכורות בSMS ו/או דוא"ל</p>
            </>
        );

    }

    return (

        <div className='row'>
            <div className='col-md-12 col-lg-10'>
                <div className="alert alert-info">
                    {takanonMessage}
                    <TakanonCheckBox />
                </div>
            </div>
        </div>
    );
}