import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { permitRequestData, permitRequestDummyData } from "../data/permitRequestData";
import { requestSchema } from "../validations/createRequest/permitRequestValidation";
import PermitRequestPage from "../pages/permitRequest/PermitRequestPage";
import useAddPermitRequestApi, {
    BAD_REQUEST, FILES_NOT_VALID, GENERAL, PAYMENT_FAILED,
    VALID_PERMIT_ALREADY_EXIST_ERROR, VALID_REQUEST_ALREADY_EXIST
} from "../api/useAddPermitRequestApi";
import { convertFileToSendToServer } from '../helpers/convertors';
import { COMPONENT_STATE_ACTION, useComponentState } from '../hooks/useComponentState';
import { useProjectConfigContext } from "./ProjectConfigContext";
import { calcPaymentAmount } from "../helpers/calcPaymentAmount";
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from "../validations/errorMessages";
import { useAddReqConclusionModal } from "../hooks/useAddReqConclusionModal";


export const PermitRequestContext = createContext();

export const PermitRequestProvider = () => {

    const { projectConfigState } = useProjectConfigContext();
    const tdsConfig = projectConfigState.tdsConfig;
    const colorAndType = tdsConfig.colorAndType;
    const hasAdditionalPersonIdFile = projectConfigState.hasAdditionalPersonIdFile;

    const navigate = useNavigate();
    const [openSendReqConfirmModal, setOpenSendReqConfirmModal] = useState(false);
    const [pageState, pageStateDispatch] = useComponentState();
    const addPermitRequestApi = useAddPermitRequestApi();
    const { closeAddReqConclusionModal: closeAddReqConclusionModalFunc,
        openAddReqConclusionModal, setDataAddReqConclusionModal, conclusionModalState } = useAddReqConclusionModal();

    const { errors, values, touched, isValid, handleBlur,
        setFieldValue, setFieldTouched, validateField, validateForm } = useFormik({
            initialValues: { ...permitRequestData },
            validateOnMount: true,
            validateOnChange: false,
            validationSchema: requestSchema,
            validateOnBlur: true,
        });

    useEffect(() => {
        validateForm();
    }, [values.vColor, values.vType, values.streetId]);

    useEffect(() => {

        const newAmount = calcPaymentAmount(values.permitType, tdsConfig);
        setFieldValue('paymentAmount', newAmount);
        setFieldValue('carDetails.hasColorAndType', colorAndType);
        setFieldValue('personDetails.hasAdditionalPersonIdFile', hasAdditionalPersonIdFile);
    }, [projectConfigState]);

    useEffect(() => {

        const newAmount = calcPaymentAmount(values.permitType, tdsConfig);
        setFieldValue('paymentAmount', newAmount);
    }, [values.permitType]);

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value);
        };
    }, []);

    async function handleSubmit() {

        const formErrors = await validateForm(values);

        if (!isValid) {
            setAllKeysInObjectAsToutch('', formErrors);
            onFocusAlert();
            pageStateDispatch({
                type: COMPONENT_STATE_ACTION.FAIL_STATE,
                message: 'בעיה עם אחד או יותר מהשדות, אנא תקן ונסה לשלוח שוב.'
            });

            console.log(`errors: ${JSON.stringify(errors)}`);
            console.log(`values: ${JSON.stringify(values)}`);
            return;
        }

        setOpenSendReqConfirmModal(true);
    }

    function setAllKeysInObjectAsToutch(prevParants, obj) {

        for (const key in obj) {
            if (!obj.hasOwnProperty(key))
                continue;

            const superKey = prevParants ? `${prevParants}.${key}` : key;
            const value = obj[key];

            if (typeof value === 'object' && !Array.isArray(value) && value !== null) {

                setAllKeysInObjectAsToutch(superKey, value);
            } else {
                setFieldTouched(superKey, true, false);
            }

        }
    }

    async function handleChangeFile(inputName, fileName, file) {

        const result = {
            content: '',
            name: '',
            size: 0,
            contentType: '-1'
        }

        if (!file) {

            setFieldValue(inputName, result, true);
            return;
        }

        if (file?.size > MAX_FILE_SIZE) {

            result.contentType = MAX_FILE_SIZE + 100;
            setFieldValue(inputName, result, true);
            return;
        }

        if (!SUPPORTED_FORMATS.includes(file?.type)) {

            result.contentType = file.type;
            setFieldValue(inputName, result, true);
            return;
        }


        try {

            const result = await convertFileToSendToServer(file, fileName);
            setFieldValue(inputName, result, true);
        } catch (error) {

            setFieldValue(inputName, {}, true);
        }
    }

    function closeAddReqConclusionModal() {

        closeAddReqConclusionModalFunc();
        navigate(`/home/${values.projectId}`);
    }

    async function submitPermitRequest() {

        try {

            pageStateDispatch({
                type: COMPONENT_STATE_ACTION.LOADING_STATE
            });

            const data = { ...values };

            const response = await addPermitRequestApi(data);

            if (response.status === 1) {

                const asmachta = response.asmachta;
                const permitRequestId = response.permitRequestId;
                const amountPayed = response.amountPayed;

                setDataAddReqConclusionModal(asmachta, permitRequestId, amountPayed);
            }
        } catch (error) {

            console.log('Error', error);
            let errorMsg = 'בעיה כללית בשרת';

            if (error.response) {

                const data = error.response.data;

                if (data.errorCode === VALID_PERMIT_ALREADY_EXIST_ERROR.code) {

                    errorMsg = VALID_PERMIT_ALREADY_EXIST_ERROR.desplayMsg;
                } else if (data.errorCode === VALID_REQUEST_ALREADY_EXIST.code) {

                    errorMsg = VALID_REQUEST_ALREADY_EXIST.desplayMsg
                } else if (data.errorCode === PAYMENT_FAILED.code) {

                    errorMsg = PAYMENT_FAILED.desplayMsg
                } else if (data.errorCode === FILES_NOT_VALID.code) {

                    errorMsg = FILES_NOT_VALID.desplayMsg
                } else if (data.errorCode === GENERAL.code) {

                    errorMsg = GENERAL.desplayMsg
                } else if (data.errorCode === BAD_REQUEST.code) {

                    errorMsg = BAD_REQUEST.desplayMsg
                }

                console.log(`Error - ${errorMsg}`);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            onFocusAlert();
            pageStateDispatch({
                type: COMPONENT_STATE_ACTION.FAIL_STATE,
                message: errorMsg
            });
        }

        pageStateDispatch({
            type: COMPONENT_STATE_ACTION.NOT_LOADING_STATE
        });
    }

    function onFocusAlert() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <PermitRequestContext.Provider value={
            {
                openSendReqConfirmModal, setOpenSendReqConfirmModal,
                handleChange, errors, values, touched, isValid, handleSubmit, handleBlur, setFieldValue,
                setFieldTouched, validateField,
                conclusionModalState, closeAddReqConclusionModal, openAddReqConclusionModal, submitPermitRequest, handleChangeFile,
                pageState
            }
        }>
            <PermitRequestPage />
        </PermitRequestContext.Provider>
    );

}

export const usePermitRequestContext = () => useContext(PermitRequestContext);