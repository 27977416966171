import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PermitRquestForm from './components/form/PermitRequestForm';
import TopDeclaration from './components/TopDeclaration';
import { usePermitRequestContext } from '../../contexts/PermitRequestContext';
import { useProjectConfigContext } from '../../contexts/ProjectConfigContext';
import LoadingModal from '../../components/loadingModal/LoadingModal';
import DangerAlert from '../../components/Alerts/DangerAlert';
import SuccessModal from './components/successModal/SuccessModal';
import PageTitle from './components/PageTitle';
import SendReqConfirmModal from './components/SendReqConfirmModal';
import Alert from '../../components/Alerts/Alert';

const PermitRequestPage = () => {

    const { setProjectId } = useProjectConfigContext();
    const { pageState } = usePermitRequestContext();

    const params = useParams();
    const projectId = params.projectId;


    useEffect(() => {

        setProjectId(projectId);
    }, [projectId]);

    return (
        <>
            <SuccessModal />
            <SendReqConfirmModal />
            {pageState.isLoading && <LoadingModal />}
            <DangerAlert isShow={pageState.hasMessage} message={pageState.message} />
            <PageTitle />
            <TopDeclaration projectId={projectId} />
            <PermitRquestForm />
        </>
    );
}

export default PermitRequestPage;